import { colors } from '../../tailwind-design-preset';
import { IconName, NoddiBasicCard, NoddiIcon } from '../atoms';
import { cn } from '../helpers/utils';
import { NoddiDummyButton } from './NoddiDummyButton';

type NoddiSelectableItemProps = {
  title: string;
  description?: string;
  isSelected: boolean;
  iconName: IconName;
  action: () => void;
  actionText: string;
  hideButton?: boolean;
};

export const NoddiSelectableItem = ({
  title,
  iconName,
  isSelected,
  description,
  action,
  actionText,
  hideButton
}: NoddiSelectableItemProps) => {
  return (
    <NoddiBasicCard
      onClick={action}
      className={cn(
        'flex min-w-75 justify-between gap-4 p-4 transition-all',
        isSelected && 'bg-primary-purple text-primary-white'
      )}
    >
      <div className='flex gap-2'>
        <NoddiIcon name={iconName} color={isSelected ? colors.primary.white : colors.primary.black} />
        <div className='flex flex-col'>
          <p className='font-semibold'>{title}</p>

          {description && <p>{description}</p>}
        </div>
      </div>
      <div className={cn('flex items-center', hideButton && 'hidden')}>
        <NoddiDummyButton
          startIcon={isSelected ? 'Minus' : 'Plus'}
          className='!text-primary-black'
          variant='secondary'
          size='small'
        >
          {actionText}
        </NoddiDummyButton>
      </div>
    </NoddiBasicCard>
  );
};
