import { t } from '@lingui/macro';
import { NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import { cn } from 'noddi-ui/src/helpers/utils';
import routes from '../../appRoutes';
import useCleanupBooking from '../../hooks/useCleanupBooking';

// TODO | New booking flow | change this for new store
// useBookingClearStore

export function useCleanUpAndNavigateToNewBooking() {
  const { cleanupBookingData } = useCleanupBooking();
  const navigate = useNavigate();

  function cleanUpAndNavigateToNewBooking() {
    cleanupBookingData();
    navigate(routes.newBookingAddressStep.getPath());
  }

  return { cleanUpAndNavigateToNewBooking };
}

type NewBookingButtonProps = {
  fullWidth?: boolean;
  defaultSize?: boolean;
};
const NewBookingButton = ({ fullWidth, defaultSize }: NewBookingButtonProps) => {
  const { cleanUpAndNavigateToNewBooking } = useCleanUpAndNavigateToNewBooking();
  return (
    <NoddiButton
      className={cn(fullWidth && 'w-full')}
      size={defaultSize ? 'default' : 'small'}
      endIcon='ArrowRight'
      onClick={cleanUpAndNavigateToNewBooking}
    >{t`New booking`}</NoddiButton>
  );
};

export default NewBookingButton;
