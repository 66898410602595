import { cn } from 'noddi-ui/src/helpers/utils';
import { KeyboardEvent, PropsWithChildren } from 'react';

type NoddiCardWrapperProps = {
  className?: string;
  onClick?: () => void;
};

export const NoddiBasicCard = ({ children, className, onClick }: PropsWithChildren<NoddiCardWrapperProps>) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (onClick && (event.key === 'Enter' || event.key === ' ')) {
      if (event.key === ' ') {
        event.preventDefault();
      }
      onClick();
    }
  };

  return (
    <div
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      onKeyDown={handleKeyDown}
      tabIndex={onClick ? 0 : undefined}
      aria-pressed={onClick ? 'false' : undefined}
      className={cn(
        'rounded-2xl bg-primary-white px-5 py-4 shadow-sm',
        onClick &&
          'transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-systemColors-grey active:shadow-cardShadow sm:hover:shadow-xl',
        className
      )}
    >
      {children}
    </div>
  );
};
