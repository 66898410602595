import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { useState } from 'react';

import { Form, Formik } from 'formik';
import { NoddiAsyncError } from 'noddi-async/src/types';
import { invalidateQueryKey } from 'noddi-async/src/utils';
import { NoddiButton, NoddiFormTextInput } from 'noddi-ui';
import { cn } from 'noddi-ui/src/helpers/utils';
import { BenefitsLogin } from '../../../components/BenefitsLogin';
import { membershipDetailSchema } from './validators';

const getDateOfBirthDto = (dateOfBirth: string) => {
  if (dateOfBirth === '' || dateOfBirth.length < 8) {
    return null;
  }
  const dateOfBirthStripped = dateOfBirth.replace(/[./-]/g, '');

  const year = dateOfBirthStripped.slice(-4);
  const month = dateOfBirthStripped.slice(2, 4);
  const day = dateOfBirthStripped.slice(0, 2);
  return `${year}-${month}-${day}`;
};

type NafFormInput = {
  // Todo :  This should be typed up as one of them being nullable and the other not, but this is a bigger cleanup
  membershipNumber: string;
  dateOfBirth: string;
};

const buildBodyParams = (data: NafFormInput, userGroupId: number, phoneNumber: string) => {
  const memberNumber = data.membershipNumber;
  const dateOfBirth = getDateOfBirthDto(data.dateOfBirth);

  if (memberNumber) {
    return {
      userGroupId,
      memberNumber,
      phoneNumber: null,
      dateOfBirth: null
    };
  }

  if (dateOfBirth) {
    return {
      userGroupId,
      memberNumber: null,
      phoneNumber,
      dateOfBirth
    };
  }

  return null;
};

interface NafMembershipValidationFormProps {
  setCustomErrorMessage: (message: string | null | NoddiAsyncError) => void;
}

export const NafMembershipValidationForm = ({ setCustomErrorMessage }: NafMembershipValidationFormProps) => {
  const { userData, currentUserGroupId, isLoggedIn } = useAuthContext();

  const [membershipNumber, setMembershipNumber] = useState<number | null>(null);
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);

  const [isMembershipNumberUsed, setIsMembershipNumberUsed] = useState(true);

  const { mutateAsync, isPending } = noddiAsync.usePost({
    type: URLKeys.postNafMembership,
    queryConfig: {
      onSuccess: async ({ data }) => {
        const { membershipAdded } = data;

        if (membershipAdded) {
          await invalidateQueryKey({ urlKey: URLKeys.getIsNAFMember });
        }
      },
      onError: (error) => {
        setCustomErrorMessage(error);
      }
    }
  });

  const activateMembership = async (data: NafFormInput, userGroupId: null | number, phoneNumber?: string) => {
    setCustomErrorMessage(null);

    if (!userGroupId) {
      return;
    }

    if (!phoneNumber) {
      setCustomErrorMessage(
        t`Your profile does not have a registered phone number. Contact customer service and we will fix it for you`
      );
      return;
    }

    try {
      const bodyParams = buildBodyParams(data, userGroupId, phoneNumber);
      // we should always have bodyParams, as we are disabling the submit button if the form is not valid
      if (bodyParams) {
        await mutateAsync(bodyParams);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const activateMemberShipAfterLogin = async (userGroupIdFromAPI: number | null, phoneNumber?: string) => {
    if (userGroupIdFromAPI && (dateOfBirth || membershipNumber)) {
      await activateMembership(
        { membershipNumber: String(membershipNumber), dateOfBirth: dateOfBirth! },
        userGroupIdFromAPI,
        phoneNumber
      );
    }
  };

  // If ur not logged in and u have filled in membership number or date of birth already, show login
  const showLogin = !isLoggedIn && (membershipNumber || dateOfBirth);

  if (showLogin) {
    return (
      <div>
        <BenefitsLogin activateMemberShipAfterLogin={activateMemberShipAfterLogin} />
        <div className='mt-4 flex w-full justify-end'>
          <NoddiButton
            variant='secondary'
            onClick={() => {
              setMembershipNumber(null);
              setDateOfBirth(null);
            }}
          >
            Back
          </NoddiButton>
        </div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        membershipNumber: membershipNumber ?? '',
        dateOfBirth: dateOfBirth ?? ''
      }}
      onSubmit={async (values) => {
        setMembershipNumber(Number(values.membershipNumber));
        setDateOfBirth(values.dateOfBirth);

        if (currentUserGroupId) {
          await activateMembership(
            {
              membershipNumber: String(values.membershipNumber),
              dateOfBirth: values.dateOfBirth ?? ''
            },
            currentUserGroupId,
            userData?.user.phoneNumber
          );
        }
      }}
      validationSchema={membershipDetailSchema(isMembershipNumberUsed)}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <div className={cn('flex flex-col gap-2', !isMembershipNumberUsed && 'hidden')}>
            <NoddiFormTextInput name='membershipNumber' label={t`NAF membership number`} placeholder='1234...' />
            <p>
              <Trans>Don't remember your NAF membership number?</Trans>
            </p>
            <NoddiButton
              variant='link'
              className='pl-0'
              endIcon='ArrowRight'
              onClick={() => setIsMembershipNumberUsed(!isMembershipNumberUsed)}
            >
              <Trans>Register using date of birth instead</Trans>
            </NoddiButton>
          </div>

          <div className={cn('flex flex-col gap-2', isMembershipNumberUsed && 'hidden')}>
            <NoddiFormTextInput name='dateOfBirth' label={t`Date of birth`} placeholder={t`dd.mm.yyyy`} />

            <NoddiButton
              variant='link'
              className='pl-0'
              endIcon='ArrowRight'
              onClick={() => setIsMembershipNumberUsed(!isMembershipNumberUsed)}
            >
              <Trans>Register with membership number</Trans>
            </NoddiButton>
          </div>

          <NoddiButton
            className='mt-6 w-full'
            type='submit'
            disabled={!isValid || isSubmitting || isPending}
            loading={isSubmitting || isPending}
          >
            <Trans>Confirm membership</Trans>
          </NoddiButton>
        </Form>
      )}
    </Formik>
  );
};
