import { t, Trans } from '@lingui/macro';
import { NoddiBasicCard, NoddiDummyButton } from 'noddi-ui';
import { useCleanUpAndNavigateToNewBooking } from '../NewBookingButton/NewBookingButton';

export const WheelChangeImageCard = ({ cardText }: { cardText: string }) => {
  const { cleanUpAndNavigateToNewBooking } = useCleanUpAndNavigateToNewBooking();
  return (
    <NoddiBasicCard onClick={cleanUpAndNavigateToNewBooking} className='flex flex-col gap-3 overflow-hidden p-0'>
      <img src='/assets/images/wc.jpg' alt={t`Noddi wheel change`} />

      <div className='flex flex-col gap-3 px-6 pb-4'>
        <p>{cardText} 💜</p>
        <NoddiDummyButton endIcon='ArrowRight' className='w-full'>
          <Trans>New booking</Trans>
        </NoddiDummyButton>
      </div>
    </NoddiBasicCard>
  );
};
