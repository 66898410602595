import { Trans, t } from '@lingui/macro';
import { Rating, TextField } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { NoddiBasicCard, NoddiButton, colors } from 'noddi-ui';
import { useState } from 'react';

import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';

function RatingComponent({
  rating,
  setRating,
  label,
  hideSeparator
}: {
  rating: number | null;
  setRating: (value: number | null) => void;
  label: string;
  hideSeparator?: boolean;
}) {
  return (
    <div className='mt-2 first:mt-0'>
      <div className='py-2'>
        <p> {label}</p>
        <Rating
          style={{ color: colors.primary.purple }}
          size='large'
          name='rating'
          value={rating}
          onChange={(_, newValue) => {
            if (newValue) {
              setRating(newValue);
            }
          }}
        />
      </div>
      {!hideSeparator && <div className='h-0.25 bg-systemColors-outlineStroke' />}
    </div>
  );
}

const FeedbackAfterCompletedBooking = ({ bookingId }: { bookingId: number }) => {
  const [feedbackComment, setFeedbackComment] = useState<string | null>('');
  const [overallRating, setOverallRating] = useState<number | null>(null);
  const [politenessRating, setPolitenessRating] = useState<number | null>(null);
  const [easeOfUseRating, setEaseOfUseRating] = useState<number | null>(null);
  const [communicationRating, setCommunicationRating] = useState<number | null>(null);
  const [carRating, setCarRating] = useState<number | null>(null);

  const {
    mutateAsync: addFeedback,
    isPending: isAddFeedbackPending,
    isSuccess: isAddFeedbackSuccess,
    error: addFeedbackError
  } = noddiAsync.usePost({
    type: URLKeys.postFeedback
  });

  const isDisabled = !overallRating || !easeOfUseRating || !communicationRating || !carRating;

  return (
    <>
      {isAddFeedbackSuccess ? (
        <div className='h-[50vh]'>
          <p className='mt-4 text-6'>
            <Trans>Thank you for your feedback!</Trans> 🎊
          </p>
        </div>
      ) : (
        <NoddiBasicCard className='my-2'>
          <div className='mt-2'>
            <RatingComponent
              rating={easeOfUseRating}
              setRating={(value) => {
                setEaseOfUseRating(value);
              }}
              label={t`How easy did you find it to book the service?`}
            />

            <RatingComponent
              rating={communicationRating}
              setRating={(value) => {
                setCommunicationRating(value);
              }}
              label={t`How would you rate our communication before the appointment?`}
            />

            <RatingComponent
              rating={carRating}
              setRating={(value) => {
                setCarRating(value);
              }}
              label={t`How satisfied are you with the result of the job?`}
            />

            <RatingComponent
              rating={politenessRating}
              setRating={(value) => {
                setPolitenessRating(value);
              }}
              label={t`How polite did you find the technician to be? (optional)`}
            />

            <RatingComponent
              rating={overallRating}
              setRating={(value) => {
                setOverallRating(value);
              }}
              label={t`How likely are you to recommend Noddi to someone else?`}
              hideSeparator
            />
            <div className='mt-4'>
              <TextField
                multiline
                fullWidth
                value={feedbackComment}
                onChange={(e) => setFeedbackComment(e.target.value)}
                label={t`Any more comments`}
                placeholder={t`Comment`}
              />
            </div>
            <div className='my-4'>
              {addFeedbackError && (
                <div className='mb-3'>
                  <ApiErrorMessageWithTrans error={addFeedbackError} />
                </div>
              )}
              <NoddiButton
                loading={isAddFeedbackPending}
                className='w-full'
                disabled={isDisabled}
                onClick={() => {
                  if (isDisabled) {
                    return;
                  }

                  addFeedback({
                    customerComment: feedbackComment,
                    customerRatingCarResult: carRating,
                    customerRatingCommunication: communicationRating,
                    customerRatingOverall: overallRating,
                    customerRatingPoliteness: politenessRating,
                    customerRatingEaseOfUse: easeOfUseRating,
                    bookingId
                  });
                }}
              >
                <Trans>Send feedback</Trans>
              </NoddiButton>
            </div>
          </div>
        </NoddiBasicCard>
      )}
    </>
  );
};

export default FeedbackAfterCompletedBooking;
