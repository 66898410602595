import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { NoddiButton, NoddiIcon, colors } from 'noddi-ui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import routes from '../../appRoutes';

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const BookingContainer = styled.div`
  max-width: 700px;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  padding: 48px 20px 64px 20px;
  height: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    background-image: url('/assets/svgs/booking/payment.svg');
    background-position: 0px;
    background-size: 700px 100%;
    background-repeat: no-repeat;
  }
`;

type ErrorTypes = 'capture' | 'cancelled' | 'authorization' | 'failed';

function getErrorTextBasedOnErroType(error: ErrorTypes) {
  switch (error) {
    case 'cancelled':
      return 'Betaling avbrutt';
    case 'authorization':
    case 'failed':
    case 'capture':
      return 'Betaling feilet';
    default:
      return 'Betaling feilet';
  }
}

const PaymentInfo = () => {
  const navigate = useNavigate();
  const data = useParams<{ slug: string }>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const transactionId = searchParams.get('transaction_id');
  const error = searchParams.get('error') as ErrorTypes;

  const isPaymentSuccessful = Boolean(transactionId) && error !== 'capture';

  return (
    <TopContainer>
      <InnerContainer>
        <BookingContainer>
          <div style={{ marginBottom: 24 }}>
            {isPaymentSuccessful ? (
              <div className='flex w-full flex-col items-center gap-4 text-center text-primary-black'>
                <h1 className='font-bold text-10 sm:text-14'>
                  <Trans>Payment successful!</Trans> 🎉
                </h1>

                <h3 className='mb-2 mt-12 text-5 text-primary-black sm:text-6'>
                  <Trans>
                    <b> We value your feedback. </b> Help us improve by sharing your experience.
                  </Trans>
                </h3>

                {data?.slug && (
                  <NoddiButton onClick={() => navigate(routes.bookingInfo.getPath({ slug: data.slug! }))}>
                    <Trans>Give feedback</Trans>
                  </NoddiButton>
                )}
              </div>
            ) : (
              <>
                <Typography variant='h1' mb={2} textAlign='center'>
                  Betaling feilet
                </Typography>
                <Typography style={{ marginTop: 12 }} textAlign='center'>
                  {getErrorTextBasedOnErroType(error)}
                </Typography>
                {Boolean(transactionId) && (
                  <IconContainer>
                    <NoddiIcon name='Alert' color={colors.primary.orange} className='-mx-6 size-25' />
                  </IconContainer>
                )}
                <NoddiButton
                  className='mt-6 w-full'
                  onClick={() => {
                    navigate(routes.bookingInfo.getPath({ slug: data.slug! }));
                  }}
                >
                  Prøv på nytt
                </NoddiButton>
              </>
            )}
          </div>
        </BookingContainer>
      </InnerContainer>
    </TopContainer>
  );
};

export default PaymentInfo;
