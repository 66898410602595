import { CircularProgress } from '@mui/material';
import { type VariantProps } from 'class-variance-authority';

import { ReactNode } from 'react';
import { IconName, NoddiIcon, sizeMap } from '../atoms';
import { cn } from '../helpers/utils';
import { buttonVariants, iconColorVariantsInNoddiButton } from './NoddiButton';

type NoddiDummyButtonProps = VariantProps<typeof buttonVariants> & {
  loading?: boolean;
  className?: string;
  children: ReactNode;
} & ({ startIcon?: IconName; endIcon?: never } | { endIcon?: IconName; startIcon?: never });

export const NoddiDummyButton = ({
  className,
  variant,
  size,
  endIcon,
  startIcon,
  loading,
  children
}: NoddiDummyButtonProps) => {
  const iconSize = size === 'small' ? 'small' : 'medium';
  const buttonClassName = cn(buttonVariants({ variant, size, className }));
  const iconColor = iconColorVariantsInNoddiButton({ variant });

  return (
    <div className={buttonClassName}>
      {loading ? (
        <CircularProgress color='inherit' size={sizeMap[iconSize]} />
      ) : (
        <>
          {startIcon && (
            <div>
              <NoddiIcon color={iconColor} name={startIcon} size={iconSize} />
            </div>
          )}
          <span className='text-left'>{children}</span>
          {endIcon && (
            <div>
              <NoddiIcon color={iconColor} name={endIcon} size={iconSize} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
